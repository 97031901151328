module.exports = (defaultLang, defaultFrontpageTag) => page => {
  if (!page.lang && !page.uid) {
    return
  }
  const subPageTags = ['Working', 'Living', 'Hiring']
  const lang = page.lang.substring(0, 2)
  const isFront = page.tags && page.tags.indexOf(defaultFrontpageTag) !== -1
  const subPage = page.tags && page.tags.find(tag => subPageTags.includes(tag))
  const parts = ['/']

  if (lang !== defaultLang) {
    parts.push(lang, '/')
  }
  if (subPage) {
    parts.push(subPage.toLowerCase(), '/')
  }
  if (!isFront) {
      parts.push(page.uid, '/')
  }
  return parts.join('')
}
